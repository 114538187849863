<template src="./ResearchGroupInfo.html"></template>

<script>
import idiService from "@/services/Idi";
import ChosenUniversity from "../../components/ChosenUniversity/ChosenUniversity";

export default {
  name: "research-group-info",
  components: {
    ChosenUniversity,
  },
  data() {
    return {
      groupName: null,
      descriptionImage: null,
      groupDescription: null,
      groupImage: null,
      publications: null,
      participation: { groupCoordinator: [], groupParticipant: [] },
      userId: null,
      groupCategory: null,
      groupInstitution: null,
      groupCoordinator: null,
      buttonSend: false,
      role: "",
    };
  },
  created() {
    this.getInitData();
    this.role = this.$store.state.User.user.role.name;
    this.userId = this.$store.state.User.user.id;
  },
  methods: {
    async getInitData() {
      try {
        const groupId = this.$route.params.id;
        this.participation = await idiService.groupParticipantsAndRol();
        const groupById = await idiService.listOneGroup(groupId);
        this.groupName = groupById.name;
        this.groupDescription = groupById.description;
        this.groupImage = groupById.fileImg;
        this.descriptionImage = groupById.descriptionImg;
        this.groupCategory = groupById.category.name;
        this.groupInstitution = groupById.institutionName;
        this.groupCoordinator = groupById.coordinator;
        this.publications = await idiService.getGroupPublications(groupId);
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    redirectTo() {
      this.$router.push({ path: `info/${this.$route.params.id}` });
    },
    async sendEmail() {
      try {
        const obj = {
          groupId: this.$route.params.id,
          userId: this.userId,
        };
        await idiService.sendRequestJoinGroup(obj);
        (this.buttonSend = true),
          this.$buefy.toast.open({
            duration: 5000,
            message:
              "Se envió correo con éxito al coordinador del grupo con tu información de usuario. De ser necesario el coordinador se pondrá en contacto contigo!",
            position: "is-bottom",
            type: "is-success",
          });
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
  computed: {
    validateCoordinator() {
      const groupId = this.$route.params.id;
      let coordinatorValidation = false;
      if (this.participation) {
        coordinatorValidation = this.participation.groupCoordinator.some(
          (group) => group == groupId
        );
      }
      return coordinatorValidation;
    },
    validateParticipant() {
      const groupId = this.$route.params.id;
      let coordinatorParticipant = false;
      if (this.participation) {
        coordinatorParticipant = this.participation.groupParticipant.some(
          (group) => group == groupId
        );
      }
      return coordinatorParticipant;
    },
    roleValidation() {
      return ["University"].some((a) => a === this.role);
    },
    roleValidationAdminAnnouncement() {
      return ["AdministratorCall"].some((a) => a === this.role);
    },
    roleValidationStudentOrTeacher() {
      return ["Student", "Teacher"].some((a) => a === this.role);
    },
    roleValidationAdmin() {
      return ["Administrator"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./ResearchGroupInfo.scss"></style>
